.callout-block {
  background-color: rgba(239, 250, 252, 0.589);
  padding: 2rem;
  border-radius: 0.25rem;
  box-shadow:  0 0 1px rgb(133, 133, 133), 1px 1px 3px rgb(192, 192, 192);

  h3 {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.4rem;

    small {
      font-size: 0.9rem;
      display: block;
      font-weight: normal;
      font-style: oblique;
    }
  }

  .btn-lg {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
  }
}