
.social-media-block {
  .social-media-links-content {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
  }

  .social-media-link-wrapper {
    padding-right: 1rem;
  }

  .type-telegram a {
    color: #0088CC;
  }
  .type-facebook a {
    color: #4267B2;
  }
  .type-twitter a {
    color: #1DA1F2;
  }
  .type-soundcloud a {
    color: #FE5000;
  }

  .link-tree-link {
    text-decoration: none;
    font-size: 0.8rem;
  }

  .social-logo {
    // color: black;
    img {
      height: 2rem;
      max-width: 1.8rem;
    }
  }

  a.social-media-link  {
    text-decoration: none;
    i {
      font-size: 2rem;
    }
    span {
      display: none;
    }
  }

}

