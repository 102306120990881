.social-media-block .social-media-links-content {
  flex-direction: row;
  padding-top: 1rem;
  display: flex;
}

.social-media-block .social-media-link-wrapper {
  padding-right: 1rem;
}

.social-media-block .type-telegram a {
  color: #08c;
}

.social-media-block .type-facebook a {
  color: #4267b2;
}

.social-media-block .type-twitter a {
  color: #1da1f2;
}

.social-media-block .type-soundcloud a {
  color: #fe5000;
}

.social-media-block .link-tree-link {
  font-size: .8rem;
  text-decoration: none;
}

.social-media-block .social-logo img {
  max-width: 1.8rem;
  height: 2rem;
}

.social-media-block a.social-media-link {
  text-decoration: none;
}

.social-media-block a.social-media-link i {
  font-size: 2rem;
}

.social-media-block a.social-media-link span {
  display: none;
}

.callout-block {
  background-color: #effafc96;
  border-radius: .25rem;
  padding: 2rem;
  box-shadow: 0 0 1px #858585, 1px 1px 3px silver;
}

.callout-block h3 {
  margin-top: 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.callout-block h3 small {
  font-size: .9rem;
  font-style: oblique;
  font-weight: 400;
  display: block;
}

.callout-block .btn-lg {
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
/*# sourceMappingURL=index.6f0a3db4.css.map */
